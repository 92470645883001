<ng-container *ngIf="('showLinkToFaq' | feature | async)">
    <ng-container *ngIf="user()">
        <div>
            <a [routerLink]="('frame.footer.faq.filename' | msgKey) + (user().uiLanguage) + '.pdf'"
               target="_blank">
                {{"frame.footer.faq" | msgKey}}
            </a>
        </div>
    </ng-container>
</ng-container>
