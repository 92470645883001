import { Configuration } from '../../../type/configuration';

export default {
	CKEditorHandler: {
		toolbars: {
			toolbar_fliesstext: [
				[
					'Bold',
				], [
					'BulletedList',
				], [
					'SpecialChar',
				],
			],
			toolbar_stoerer: [
				[
					'Styles',
				], [
					'Bold',
				], [
					'BulletedList',
				], [
					'SpecialChar',
				],
			],
			toolbar_adb: [
				[
					'Bold',
				], [
					'SpecialChar',
				],
			],
			toolbar_text: [
				[
					'Styles',
				], [
					'Bold',
				], [
					'BulletedList',
				], [
					'NumberedList',
				], [
					'SpecialChar',
				],
			],
		},
		config: {
			stylesCombo_stylesSet: 'mrStyles',
		},
		stylesets: {
			name: 'mrStyles',
			styles: [
				{
					name: 'Schwarz',
					element: 'font',
					attributes: {
						'class': 'defaultText',
					},
				}, {
					name: 'Grün',
					element: 'font',
					attributes: {
						'class': 'ckeditorImportant',
					},
				},
			],
		},
	},
} as Configuration;