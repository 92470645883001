import { AnchorStatusModule } from '../header/shared/elements/anchor-status/anchor-status.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavUserComponent } from './side-nav-user/side-nav-user.component';
import { SideNavHotlinksComponent } from './side-nav-hotlinks/side-nav-hotlinks.component';
import { SideNavItemComponent } from './shared/side-nav-item/side-nav-item.component';
import { RouterModule } from '@angular/router';
import { MsgKeyModule } from '../../shared/translate/msg-key.module';
import { SideNavUserItemComponent } from './side-nav-user/side-nav-user-item/side-nav-user-item.component';
import { SideNavHotlinkComponent } from './side-nav-hotlinks/side-nav-hotlink/side-nav-hotlink.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MsgKeyModule,
		AnchorStatusModule
	],
	exports: [
		SideNavUserComponent,
		SideNavHotlinksComponent
	],
	declarations: [
		SideNavUserComponent,
		SideNavHotlinksComponent,
		SideNavItemComponent,
		SideNavUserItemComponent,
		SideNavHotlinkComponent
	]
})
export class SideNavModule {}
