import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { computed, Directive, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AjaxRotatorService } from '../../navigation/ajax-rotator.service';
import {
	AddAjaxRotatorAction, HideSideNavigationAction, RemoveAjaxRotatorAction, SetFixedWidthAction, SetResponsiveWidthAction,
	ShowMainNavigationAction, ShowSideNavigationAction,
} from '../../navigation/store/navigation.actions';
import { Breadcrumb } from '../generic-compounds/breadcrumb/models/breadcrumb';
import { RemoveBreadcrumbsAction, RemoveLastBreadcrumbsAction, UpdateBreadcrumbsAction } from '../store/actions/breadcrumbs.actions';
import { ApplicationState } from '../store/application-state';
import { BreadcrumbsQuery } from '../store/reducers/breadcrumbs.reducer';
import { ErrorHandling } from './error-handling';
import { State } from './models/state';

@Directive()
export abstract class RoutedPage<T = unknown, V = any> extends ErrorHandling {

	protected state: State<T>;
	hasBackLink = computed(() => !!this.store.selectSignal(BreadcrumbsQuery.getBacklink)());
	rotatorService: AjaxRotatorService;

	constructor(
		protected store: Store<ApplicationState>,
		protected route: ActivatedRoute,
		protected router: Router,
		protected breadcrumb?: Breadcrumb,
	) {
		super();
		this.rotatorService = inject(AjaxRotatorService);
		this.state = this.route.snapshot.data['state'];
		const pristine = this.route.snapshot.queryParams['pristine'];
		if (pristine === '1') {
			this.state.update(null);
			this.state.data = null;
		}
	}

	ngOnInit(): void {
		this.initPageView();
	}

	setPathParam(config: V): void {
		const routePath = this.route.routeConfig.path.split('/');
		const splittedRoute = routePath[0].split(':');
		const newUrl = routePath.map(route => {
			const paramValues = route.split(':');
			if (paramValues.length > 1) {
				const pathVariable = config[paramValues[1]];
				if (pathVariable) {
					return encodeURIComponent(pathVariable);
				}
				return '';
			} else {
				return paramValues[0];
			}
		});
		this.router.navigateByUrl(`/${ newUrl.join('/') }`);

	}

	getPathParams(): Observable<V> {
		return this.route.paramMap.pipe(
			map(paramMap => {

				const pathParams = {} as V;
				paramMap.keys.forEach(key => {
					pathParams[key] = paramMap.get(key);
				});
				return pathParams;

			}),
		);
	}

	protected autoRemoveAjaxRotator(): boolean {
		return true;
	}

	protected overwriteNavigation(): boolean {
		return true;
	}

	protected initPageView(): void {
		// Dispatch Actions in Zone
		setTimeout(() => {
			if (this.autoRemoveAjaxRotator()) {
				this.rotatorService.removePageLoadRotator();
			}
			if (this.overwriteNavigation()) {

				if (this.isMainNavVisible()) {
					this.store.dispatch(new ShowMainNavigationAction());
				}

				if (this.isSideNavVisible()) {
					this.store.dispatch(new ShowSideNavigationAction());
				} else {
					this.store.dispatch(new HideSideNavigationAction());
				}

				if (this.isPageResponsive()) {
					this.store.dispatch(new SetResponsiveWidthAction());
				} else {
					this.store.dispatch(new SetFixedWidthAction());
				}
			}
			if (this.breadcrumb) {
				const resetBreadcrumbs = coerceBooleanProperty(this.route.snapshot.queryParams['resetBreadcrumbs']);
				const breadcrumbClone = JSON.parse(JSON.stringify(this.breadcrumb));
				breadcrumbClone.url = this.trimNonPersistentParams(this.breadcrumb.url);
				breadcrumbClone.id = this.breadcrumb.id;
				this.store.dispatch(new UpdateBreadcrumbsAction({ ...breadcrumbClone, resetBreadcrumbs }));
			}
		});
	}

	temporaryRotator<U>(observable: Observable<U>): Observable<U> {
		this.store.dispatch(new AddAjaxRotatorAction());
		return observable.pipe(
			tap(() => {
				this.store.dispatch(new RemoveAjaxRotatorAction());
			}),
		);
	}

	protected isSideNavVisible(): boolean {
		return true ? false : true;
	}

	protected isPageResponsive(): boolean {
		return false;
	}

	protected isMainNavVisible(): boolean {
		return true;
	}

	public back(): void {
		this.store.pipe(select(BreadcrumbsQuery.getBacklink))
			.pipe(
				take(1),
				map(breadcrumb => {
					if (breadcrumb.url.indexOf('.do') > 0 && breadcrumb.url.indexOf('jump.do') > -1) {
						return encodeURIComponent('/jump.do?id=' + breadcrumb.id);
					}
					return breadcrumb.url;
				}),
			)
			.subscribe(url => {
				this.router.navigateByUrl(url);
			});
	}

	protected forward(parameters = '', removeLastBreadcrumb = true): void {
		if (removeLastBreadcrumb) {
			this.store.dispatch(new RemoveLastBreadcrumbsAction());
		}
		this.router.navigateByUrl(`/strutsreturn.do?forwardTo=${ parameters }`);
	}

	protected updateBreadcrumb(breadcrumb: Breadcrumb): void {
		breadcrumb.url = this.trimNonPersistentParams(breadcrumb.url);
		this.store.dispatch(new UpdateBreadcrumbsAction(breadcrumb));
	}

	ngOnDestroy(): void {
		this.rotatorService.addPageLoadRotator();
		this.store.dispatch(new RemoveBreadcrumbsAction());
	}

	protected trimParameter(paramName: string, url?: string): string {
		const trimUrl = url || this.router.url;
		let index;
		const firstParamIndex = trimUrl.indexOf('?' + paramName);
		if (firstParamIndex > -1) {
			index = firstParamIndex;
		} else {
			index = trimUrl.indexOf('&' + paramName);
		}
		if (index > -1) {
			const start = trimUrl.substring(0, index);
			const rest = trimUrl.substring(index);
			let correctUrl: string;
			const moreParamsIndex = rest.indexOf('&', paramName.length + 1);
			if (moreParamsIndex > -1) {
				const end = rest.substring(moreParamsIndex);
				correctUrl = start + end;
			} else {
				correctUrl = start;
			}
			return correctUrl;
		}
		return trimUrl;
	}

	protected trimNonPersistentParams(url: string): string {
		if (url.indexOf('breadcrumb=true') === -1) {
			const persistentParamsString: string = this.route.snapshot.queryParams['persistentParams'];
			const persistentParams = persistentParamsString ? persistentParamsString.split('__') : [];
			Object.keys(this.route.snapshot.queryParams)
				.filter(key => persistentParams.indexOf(key) === -1)
				.forEach(key => url = this.trimParameter(key, url));
			if (url.indexOf('?') === -1 && url.indexOf('&') > -1) {
				url = url.substring(0, url.indexOf('&')) + '?' + url.substring(url.indexOf('&') + 1);
			}
			if (url.indexOf('?') === -1) {
				url += '?breadcrumb=true';
			} else {
				url += '&breadcrumb=true';
			}
		}
		return url;
	}

	public getUuid(element: UUIDContainer) {
		if (!element.uuid) {
			element.uuid = UUID.UUID();
		}
		return element.uuid;
	}
}

interface UUIDContainer {
	uuid?: string;
}
