<div *ngIf="basketDocTypes()"
	 (mouseenter)="open()"
	 (mouseleave)="close()"
	 [class.active]="basketHasItems()"
	 [bas-tooltip]="basketTooltip() | msgKey">
	<a routerLink="/workflow.do?from=menu&to=basket">
		<mat-icon svgIcon="shopping_cart"></mat-icon>
	</a>
	<div *ngIf="basketHasItems()"
		 class="basket-content"
		 [@openState]="state">
		<div *ngFor="let doctype of basketDocTypes()"
			 class="basket-item">
			<span>{{doctype.nameKey | msgKey}}</span>
			<span>({{doctype.count}})</span>
		</div>
		<a routerLink="/workflow.do?from=menu&to=basket"
		   class="basket-link">
			<span>
				{{'text.link.tobasket' | msgKey}}
			</span>
		</a>
	</div>
</div>