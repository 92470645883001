<div (mouseenter)="open()" (mouseleave)="close()">
	<div class="quick-nav-trigger" [ngClass]="state" [title]="'text.logtype.quicknav' | msgKey">
		<svg version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 17.1 17.5"
			style="enable-background:new 0 0 17.1 17.5;">
			<style type="text/css">
				.st0 {
					fill: #FFFFFF;
					stroke: #000000;
					stroke-linecap: round;
					stroke-linejoin: round;
					stroke-miterlimit: 10;
				}

				.st1 {
					fill: none;
					stroke: #000000;
					stroke-miterlimit: 10;
				}
			</style>
			<g>
				<path d="M5.3,16.8H0.5v-4.8h4.8V16.8z M1.5,15.8h2.8v-2.8H1.5V15.8z"></path>
			</g>
			<g>
				<path d="M10.9,16.8H6.1v-4.8h4.8V16.8z M7.1,15.8h2.8v-2.8H7.1V15.8z"></path>
			</g>
			<g>
				<path d="M16.5,16.8h-4.8v-4.8h4.8V16.8z M12.8,15.8h2.8v-2.8h-2.8V15.8z"></path>
			</g>
			<g>
				<path d="M10.9,5.4H6.1V0.6h4.8V5.4z M7.1,4.4h2.8V1.6H7.1V4.4z"></path>
			</g>
			<g>
				<rect x="8" y="4.9" width="1" height="8.1"></rect>
			</g>
			<g>
				<polygon points="14.5,13 13.5,13 13.5,10 3.5,10 3.5,13 2.5,13 2.5,9 14.5,9  "></polygon>
			</g>
		</svg>
	</div>
	<div [@openState]="state" class="quick-nav-container mat-elevation-z8">
		<ng-container *ngFor="let item of items()">
			<ng-container *ngIf="item.name === 'root'; else normal">
				<div *ngFor="let rootchild of item.children" class="quick-nav-item">
					<a [routerLink]="rootchild.url" [queryParams]="rootchild.query"
					   (click)="onClick(rootchild)" bas-anchor-status [parameters]="rootchild.parameters">
						<span>{{ rootchild.name || (rootchild.nameKey | msgKey) }}</span>
					</a>
					<div class="quick-nav-sub-item" *ngFor="let child of rootchild.children">
						<a [routerLink]="child.url" [queryParams]="child.query"
						   (click)="onClick(child)" bas-anchor-status [parameters]="child.parameters">
							<span>{{ child.name || (child.nameKey | msgKey) }}</span>
						</a>
					</div>
				</div>
			</ng-container>
			<ng-template #normal>
				<div class="quick-nav-item">
					<a [routerLink]="item.url" [queryParams]="item.query"
					   (click)="onClick(item)" bas-anchor-status [parameters]="item.parameters">
						<span>{{ item.name || (item.nameKey | msgKey) }}</span>
					</a>
					<div class="quick-nav-sub-item" *ngFor="let child of item.children">
						<a [routerLink]="child.url" [queryParams]="child.query"
						   (click)="onClick(child)" bas-anchor-status [parameters]="child.parameters">
							<span>{{ child.name || (child.nameKey | msgKey) }}</span>
						</a>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</div>
</div>