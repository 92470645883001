import { HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AppComponent } from './app.component';
import { sharedDeclarations, sharedProviders } from './shared-dependencies';
import { sharedModules } from './shared-modules';
import { ShowOnTouchedErrorStateMatcher } from './shared/material/error-state-matcher/show-on-touched';

@NgModule({
	declarations: [
		...sharedDeclarations,
	],
	imports: [
		...sharedModules,
		HttpClientXsrfModule,
	],
	providers: [
		...sharedProviders,
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
		{ provide: ErrorStateMatcher, useClass: ShowOnTouchedErrorStateMatcher },
		{ provide: 'CMS_BACKEND_URL', useValue: './microservice/cms/api' },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
