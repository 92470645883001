<header>
	<ng-container *ngIf="showMainNav(); else notLoggedIn">
		<div class="meta-navigation">
			<div class="logo">
				<a routerLink="/workflow.do?from=menu&to=home"
				   class="defaultLink">
					<img src="portallogo.do?doOriginal=1">
				</a>
			</div>
			<nav>
				<bas-nav-search-field></bas-nav-search-field>

				<div class="nav-action" [class.active]="prefsActive()"
					 [bas-tooltip]="preferencesTooltip()">
					<a routerLink="/workflow.do?from=menu&to=preferences">
						<mat-icon svgIcon="settings"></mat-icon>
					</a>
				</div>

				<bas-nav-basket class="nav-action" [class.active]="basketActive()"></bas-nav-basket>

				<div class="nav-action" [class.active]="contactActive()"
					 [bas-tooltip]="'image.metanav.contact.alt' | msgKey">
					<a [routerLink]="'image.metanav.contact.email' | msgKey">
						<mat-icon svgIcon="phone"></mat-icon>
					</a>
				</div>

				<div class="logout"
					 [bas-tooltip]="'image.metanav.logout.alt' | msgKey">
					<a routerLink="/logout.do"
					   (click)="logout()">
						<mat-icon svgIcon="logout"></mat-icon>
					</a>
				</div>
			</nav>
		</div>

		<nav>
			<div class="main-navigation primaryCiColor">
				<ul>
					<ng-container *ngFor="let topItems of items()">
						<ng-container *ngIf="topItems.name === 'root'; else normal">
							<bas-nav-item *ngFor="let item of topItems.children"
										  [item]="item"></bas-nav-item>
						</ng-container>
						<ng-template #normal>
							<bas-nav-item [item]="topItems"></bas-nav-item>
						</ng-template>
					</ng-container>
				</ul>
				<bas-nav-quick></bas-nav-quick>
			</div>
		</nav>
	</ng-container>

	<ng-template #notLoggedIn>
		<div class="logo login">
			<img src="page/style/css/theme/img/logo.png">
		</div>
	</ng-template>
</header>