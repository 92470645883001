<ng-container *ngIf="!isMobile; else mobileAnsicht">
	<div class="background">
		<div class="page" [class.page--responsive]="isResponsive()">
			<bas-nav-header></bas-nav-header>
			<div class="content">
				<div class="side-nav"
					 *ngIf="showSideNav()">
					<bas-side-nav-hotlinks></bas-side-nav-hotlinks>
				</div>
				<div class="content-area">
					<router-outlet></router-outlet>
					<div class="links"
						 *ngIf="showMainNav() && canSeeDebugLinks()">
						<a bas-link
						   class="debugItem"
						   routerLink="/service/uilab-branching-mask.do">UI Lab</a>
					</div>
				</div>
			</div>
			<bas-nav-footer></bas-nav-footer>
		</div>
	</div>
</ng-container>

<ng-template #mobileAnsicht>
	<div class="mobile-page"
		 [class.mobile-login]="isLoginPage()">
		<bas-nav-header *ngIf="showNavHeader()"></bas-nav-header>
		<bas-mobile-nav *ngIf="showMobileNav()"></bas-mobile-nav>
		<router-outlet></router-outlet>
		<bas-nav-footer *ngIf="showNavFooter()"></bas-nav-footer>
	</div>
</ng-template>
