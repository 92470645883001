import {ChangeDetectionStrategy, Component, signal} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Store} from '@ngrx/store';
import {User} from '../../../../models/user';
import {ApplicationState} from '../../../shared/store/application-state';
import {UserQuery} from '../../../shared/store/reducers/user.reducer';

@Component({
	selector: 'bas-footer-faq',
	templateUrl: './footer-faq.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterFaqComponent {

	user = signal<User>(null);

	constructor(private store: Store<ApplicationState>) {
		this.store.select(UserQuery.getUser)
			.pipe(takeUntilDestroyed())
			.subscribe(user => this.user.set(user));
	}
}
